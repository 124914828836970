const IndexPage = () => (
  <div
    className="w-screen h-screen relative text-center flex flex-col justify-center gap-y-12 p-8 bg-cover bg-left-bottom"
    style={{
      backgroundImage:
        'url("https://res.cloudinary.com/globobeet/image/upload/v1637348880/_PSK6756_sdhogr.jpg")',
    }}
  >
    <div className="absolute inset-0 bg-gray-900 opacity-90" />
    <div className="relative md:absolute md:inset-16 flex justify-center items-center">
      <div className="md:-mt-16">
        <p className="font-semibold uppercase tracking-widest text-gray-400">Coming Soon</p>
        <h1 className="font-semibold uppercase text-3xl md:text-6xl mt-4 leading-relaxed tracking-widest">
          High Road <br />
          <span className="block text-lg mt-2">Brewing Company</span>
        </h1>
      </div>
    </div>
    <div className="relative md:absolute md:inset-16 flex justify-center items-end">
      <div className="grid grid-cols-1 gap-y-8 gap-x-16">
        <div>
          <p className="font-semibold uppercase text-xs tracking-widest">
            Taproom <span className="text-white">(Now Open!)</span>
          </p>
          <p className="text-base mt-2 text-gray-400">
            146 Walden Way
            <br />
            Mechanicsburg, PA 17050
          </p>
          <p className="text-base mt-2 text-gray-400">Mon-Wed 4-9pm &bull; Thurs-Fri 4-11pm</p>
          <p className="text-base text-gray-400">Sat 12-11pm &bull; Sun 12-9pm</p>
        </div>
      </div>
    </div>
  </div>
);

export default IndexPage;
